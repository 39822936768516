form input {
	display: block;
	box-sizing: border-box;
	width: 100%;
	background: var(--color-gray700);
	border: 0;
	border-radius: 0.5rem;
	color: #fff;
	margin-bottom: 0.5rem;
	font-size: 1rem;
}

form button,
button.big,
a.button {
	background: var(--color-gray600);
	border: 0;
	border-radius: 0.5rem;
	color: #fff;
	font-weight: bold;
	display: block;
	padding: 1rem;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 1rem;
	user-select: none;
}

a.button {
	text-align: center;
	text-decoration: none;
	font-size: 1rem;
}

form button.primary,
button.big.primary {
	background: var(--color-action);
}

button.big.tertiary {
	background: transparent;
	color: var(--color-gray400);
	border: 1px solid var(--color-gray400);
}

form button.margin,
button.big.margin {
	margin-top: 1.5rem;
}

.input-wrap {
	position: relative;
	margin-top: 1.5rem;
}
textarea,
input {
	box-sizing: border-box;
	width: 100%;
	display: block;
	padding: 1rem;
	border: 0;
	z-index: 4;
	position: relative;
	color: var(--color-gray100);
}
::placeholder {
	color: var(--color-gray300);
}
label {
	display: block;
	position: absolute;
	top: -0.5rem;
	font-size: 0.75rem;
	line-height: 1rem;
	padding: 0 0.5rem;
	color: var(--color-gray300);
	opacity: 0;
	transition: opacity 0.2s, top 0.2s;
}
label.visible {
	opacity: 1;
	top: -1.25rem;
}
.formerror {
	border-radius: 0.5rem;
	border: 1px solid var(--color-wrong);
	margin: auto;
	padding: 0.5rem;
}
h2 button {
	border: 0;
	background: transparent;
	color: var(--color-gray100);
	padding: 0;
	cursor: pointer;
	vertical-align: text-bottom;
	margin-right: 0.5rem;
}
h2 button svg {
	width: 1.5rem;
	height: 1.5rem;
}
.alt-space {
	padding: 1rem;
	text-align: center;
}
.uploadhistory {
	color: var(--color-gray100);
	line-height: 1.5;
	padding: 1rem;
}
.uploadhistory h3 {
	margin: 0;
}
.uploadhistory p {
	margin: 1rem;
	line-height: 1.5;
}
.signin-options {
	padding-top: 0.5rem;
}
a.fb,
a.google {
	display: flex;
	align-items: center;
}
a.fb {
	background: #1877f2;
	color: #fff;
}
a.google {
	padding: 0;
	background: #4285f4;
}
.fb img,
.google img {
	width: 2rem;
	height: 2rem;
	margin-right: 2rem;
}
.google img {
	background-color: #fff;
	padding: 1rem;
	margin-right: 1.5rem;
	width: 1.5rem;
	height: 2rem;
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
}
