.app {
	text-align: center;
	width: 420px;
	margin: auto;
	display: flex;
	flex-direction: column;
	height: 100%;
}
@media (max-width: 420px) {
	.app {
		width: 100%;
	}
}

header {
	color: var(--color-gray100);
	display: flex;
	align-items: center;
}
header span {
	flex-grow: 1;
	font-size: 1.5rem;
	font-weight: bold;
	letter-spacing: 1pt;
	line-height: 3rem;
	font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}
header button,
button.close {
	background: transparent;
	padding: 0.5rem;
	border: 0;
	color: var(--color-gray300);
	cursor: pointer;
}
header button:hover,
button.close:hover {
	color: var(--color-gray100);
}
header button svg,
button.close svg {
	width: 1.5rem;
	height: 1.5rem;
}

main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.tiles {
	flex-grow: 1;
	aspect-ratio: 1/1.2;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(6, 1fr);
	gap: 0.5rem;
}

.tiles.howto {
	grid-template-rows: 1fr;
	text-align: center;
	display: inline-grid;
	aspect-ratio: 5;
}

.tiles > div {
	background: var(--color-gray700);
	border-radius: 0.5rem;
	text-transform: uppercase;
	color: #fff;
	font-size: 2rem;
	user-select: none;
	font-weight: bold;
}
.tiles > div > span {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.tiles.howto > div {
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
}

@media (max-width: 420px) {
	.tiles > div {
		line-height: 100%;
		font-size: 8vw;
	}
	.tiles.howto > div {
		width: 10vw;
		height: 10vw;
		line-height: 10vw;
		font-size: 6vw;
	}
	.tiles {
		gap: 0.5rem;
	}
}
@media (max-height: 560px) {
	.tiles {
		gap: 0.25rem;
	}
}
@media (max-height: 460px) {
	.tiles > div {
		font-size: 5vh;
	}
}

.tiles .cursor {
	position: relative;
}
.tiles .cursor::after {
	content: "";
	height: 0.2rem;
	background: var(--color-gray600);
	left: 1rem;
	right: 1rem;
	bottom: 0.5rem;
	position: absolute;
	animation: blinking 1s ease-in-out infinite;
}
@keyframes blinking {
	50% {
		opacity: 0;
	}
}

section {
	display: flex;
	justify-content: center;
	user-select: none;
}
section.board {
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	position: relative;
}
section.board .center {
	box-sizing: border-box;
	display: flex;
	aspect-ratio: 1/1.2;
	max-height: 420px;
	flex-grow: 1;
	position: relative;
	padding: 1rem;
}
@media (max-height: 520px) {
	section.board .center {
		padding: 0;
	}
}

section.board .error {
	position: absolute;
	top: -3rem;
	left: 0;
	right: 0;
	height: 2rem;
}
@media (max-height: 700px) {
	section.board .error {
		top: -2rem;
	}
}
section.board .error span {
	display: inline-block;
	color: var(--color-gray900);
	background: var(--color-gray200);
	padding: 0.5rem;
	border-radius: 0.5rem;
	animation: slidein 0.2s ease-in-out 1;
}
section.board .error.hide {
	animation: slideout 0.5s ease-in-out 1;
}
@keyframes slidein {
	0% {
		opacity: 0;
		transform: translateY(-1rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes slideout {
	100% {
		opacity: 0;
		transform: translateY(-1rem);
	}
}
.keyboard {
	margin: 0.5rem 0.5rem 1rem 0.5rem;
	touch-action: none;
}

.keyboard button {
	box-sizing: border-box;
	margin: 0;
	border: 0;
	background: var(--color-gray600);
	color: #fff;
	font-weight: bold;
	border-radius: 0.5rem;
	height: 3rem;
	text-transform: uppercase;
	cursor: pointer;
	padding: 0;
	transition: background 0.2s;
	font-size: 1rem;
}
@media (min-width: 420px) {
	.keyboard button {
		min-width: 2rem;
	}
}
@media (pointer: fine) {
	.keyboard button:hover {
		opacity: 0.9;
	}
	.keyboard button:active {
		opacity: 0.8;
	}
}

button.submit {
	background: var(--color-action);
}
button.submit.disable {
	background: var(--color-action-dark);
	color: var(--color-action-dark);
}
.keys {
	display: grid;
	gap: 0.2rem;
	grid-template-columns: repeat(11, 1fr);
	grid-template-rows: repeat(4, 1fr);
	width: 100%;
}
@media (max-width: 420px) {
	.keys {
		gap: 1vw;
	}
}
.keys .spacer {
	grid-column-start: 1;
	grid-column-end: 3;
}
.keys .undo {
	grid-column-start: 10;
	grid-column-end: 12;
	width: 70%;
	justify-self: end;
}
.keys .undo button {
	width: 100%;
}
.keys .undo svg {
	width: 1.5rem;
	height: 1.5rem;
}
.keys .enter {
	grid-column-start: 1;
	grid-column-end: 12;
	justify-self: end;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.keys .enter button {
	width: 5rem;
}

.tiles .wrong {
	background: var(--color-gray900);
}
.tiles .input,
button.input {
	background: #000;
}
.tiles .correct,
button.correct {
	background: var(--color-success);
}
.tiles .kinda,
button.kinda {
	background: var(--color-hint);
}

button.wrong {
	background: var(--color-gray900);
}

.tiles .spin {
	display: block;
	z-index: 1;
	-webkit-animation-duration: 0.5s;
	-webkit-animation-timing-function: steps(1000);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-name: spinning;
	-webkit-animation-delay: 0;
	animation-duration: 1s;
	animation-timing-function: steps(1000);
	animation-iteration-count: infinite;
	animation-name: spinning;
	animation-delay: 0;
}
.tiles .shake {
	z-index: 1;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: steps(100);
	-webkit-animation-iteration-count: 1;
	-webkit-animation-name: shake;
	-webkit-animation-delay: 0;
	-webkit-animation-play-state: running;
	animation-duration: 1s;
	animation-timing-function: steps(100);
	animation-iteration-count: 1;
	animation-name: shake;
	animation-delay: 0;
	animation-play-state: running;
}
@keyframes spinning {
	0% {
		-webkit-transform: perspective(5rem) rotateX(0);
		transform: perspective(5rem) rotateX(0);
	}
	100% {
		-webkit-transform: perspective(5rem) rotateX(359.9deg);
		transform: perspective(5rem) rotateX(359.9deg);
	}
}
@keyframes shake {
	10%,
	90% {
		-webkit-transform: translateX(-0.1rem);
		transform: translateX(-0.1rem);
	}

	20%,
	80% {
		-webkit-transform: translateX(0.2rem);
		transform: translateX(0.2rem);
	}

	30%,
	50%,
	70% {
		-webkit-transform: translateX(-0.35rem);
		transform: translateX(-0.35rem);
	}

	40%,
	60% {
		-webkit-transform: translateX(0.35rem);
		transform: translateX(0.35rem);
	}
}
.modal-wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}
.modal-overlay {
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	-webkit-transition: background 0.4s;
	transition: background 0.4s;
}
.modal-content {
	z-index: 2;
	position: relative;
	overflow: auto;
	height: 100%;
	overflow-y: auto;
}
@media (hover: none) and (pointer: coarse) {
	.modal-content {
		cursor: pointer;
	}
}
.modal {
	box-sizing: border-box;
	margin: 3rem auto;
	background: var(--color-gray900);
	border-radius: 0.5rem;
	padding: 1rem;
	box-shadow: 0 0.7rem 2rem rgb(0 0 0 / 70%);
	width: 400px;
	transition: margin 0.2s ease-in-out, width 0.2s ease-in-out;
	cursor: initial;
	color: var(--color-gray100);
	line-height: 1.25;
	animation: slidein 0.3s ease-in-out 1;
	text-align: left;
}
@media (max-width: 420px) {
	.modal {
		width: 90vw;
	}
}
.modal .top {
	display: flex;
	justify-content: flex-end;
	margin-bottom: -1rem;
}
.modal h2 {
	font-size: 1.5rem;
	margin: 0;
}
.stats-wrapper h2 {
	margin-top: 0.5rem;
}
.modal h3 {
	font-size: 1.25rem;
	margin: 0;
	padding: 0.5rem 0 1rem 0;
}
button.play {
	background: var(--color-action);
	border: 0;
	border-radius: 0.5rem;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	display: block;
	padding: 1rem;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	font-size: 1rem;
}
.example {
	background: var(--color-gray800);
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	margin-bottom: 1rem;
}
.example span {
	font-weight: bold;
	color: #fff;
	padding: 0 0.25rem;
	border-radius: 0.25rem;
}
span.correct {
	background: var(--color-success);
}
span.kinda {
	background: var(--color-hint);
}
span.wrong {
	background: var(--color-gray900);
}
.stats-wrapper {
	text-align: center;
}
.stats-wrapper .stats {
	text-align: center;
	display: grid;
	width: 90%;
	grid-template-columns: repeat(4, 1fr);
	margin: 1.5rem auto;
	font-size: 0.875rem;
	grid-gap: 0.5rem;
}
.stats-wrapper .stats .number {
	font-size: 1.5rem;
}
.stats-wrapper .nextgame {
	background: var(--color-gray800);
	border-radius: 0.5rem;
	padding: 2rem 1rem 1rem 1rem;
	text-align: center;
}
.stats-wrapper .nextgame h4 {
	font-size: 1rem;
	margin: 0;
}
.stats-wrapper .countdown {
	font-feature-settings: "tnum";
	font-variant-numeric: tabular-nums;
	padding: 1rem;
	font-size: 2rem;
}
button.share {
	background: var(--color-gray700);
	color: #fff;
	padding: 1rem 1rem 1rem 1rem;
	font-size: 1rem;
	cursor: pointer;
	line-height: 1.5;
	border: 0;
	border-radius: 0.5rem;
	font-weight: bold;
	margin-bottom: 1rem;
}
button.share:active {
	background: var(--color-gray600);
}
button.share svg {
	width: 1.5rem;
	height: 1.5rem;
	vertical-align: top;
	margin-right: 0.5rem;
}
.stats-wrapper .prev {
	margin-bottom: 2rem;
}
.contact {
	padding: 1rem 0.5rem;
	margin: 1rem 1rem 0 1rem;
	font-size: 0.75rem;
	color: var(--color-gray400);
	border-top: 1px solid var(--color-gray800);
	line-height: 1.5;
}
.contact a {
	color: var(--color-action-bright);
	text-decoration: none;
}
.contact button,
button.forgot-password {
	box-sizing: content-box;
	cursor: pointer;
	background: transparent;
	border: 0;
	color: var(--color-action-bright);
	font-size: 0.75rem;
	padding: 0;
	line-height: 1.5;
	display: inline;
	font: inherit;
	height: auto;
	padding: 0;
	perspective-origin: 0 0;
	text-align: start;
}

.keyboard .nextgame {
	margin-right: 0.5rem;
	color: var(--color-gray200);
	font-size: 0.75rem;
	display: block;
	text-align: right;
}
.keyboard .nextgame .countdown {
	font-weight: bold;
	font-feature-settings: "tnum";
	font-variant-numeric: tabular-nums;
}
.prevgame {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	text-align: left;
}
.prevgame .day {
	font-size: 0.875rem;
	flex-basis: 2rem;
	color: var(--color-gray200);
}
.prevgame .word {
	font-weight: bold;
	flex-basis: 7rem;
	white-space: nowrap;
}
.prevgame .word span {
	height: 1.25rem;
	width: 1.25rem;
	text-align: center;
	background: var(--color-gray600);
	color: #fff;
	display: inline-block;
	line-height: 1.25rem;
	border-radius: 0.25rem;
	margin-right: 1px;
}
.prevgame .word.unknown span {
	background: var(--color-gray800);
}
.prevgame .word.won span {
	background: var(--color-success);
}

.prevgame .prevstats {
	flex-grow: 1;
	display: flex;
	line-height: 0;
	flex-direction: column;
	text-align: left;
}
.prevgame .graph {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transform-origin: top left;
	-webkit-transform-origin: top left;
}
.prevgame .graph:first-of-type {
	margin-bottom: 2px;
}
.prevgame .graph > div {
	background: var(--color-gray400);
	margin-left: 1px;
	height: 5px;
	font-size: 0.675rem;
	font-weight: bold;
	box-sizing: border-box;
	padding: 1px;
	line-height: 1;
}

.prevgame .graph {
	-webkit-animation-duration: 0.35s;
	-webkit-animation-timing-function: steps(1000);
	-webkit-animation-iteration-count: 1;
	-webkit-animation-name: growin;
	-webkit-animation-delay: 0;
	animation-duration: 0.35s;
	animation-timing-function: steps(1000);
	animation-iteration-count: 1;
	animation-name: growin;
	animation-delay: 0;
}
@keyframes growin {
	0% {
		-webkit-transform: scaleX(0%);
		transform: scaleX(0%);
	}
	100% {
		-webkit-transform: scaleX(100%);
		transform: scaleX(100%);
	}
}
.legend {
	padding: 1rem 0 0 0;
	display: flex;
	font-size: 0.75rem;
	color: var(--color-gray300);
}
.legend .color {
	margin-right: 0.5rem;
	display: flex;
	align-items: center;
}
.legend .color > div {
	width: 7px;
	height: 7px;
	border-radius: 2px;
	margin-right: 2px;
}
.table-head {
	display: flex;
	text-align: left;
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 2rem;
	color: var(--color-gray200);
}
.table-head > div:first-of-type {
	flex-basis: 9rem;
}
.distribution-wrapper {
	padding: 0 0 1rem 0;
}
.distribution-wrapper h5 {
	margin: 0;
}
.distribution {
	padding-top: 0.5rem;
	color: var(--color-gray100);
	text-align: left;
	font-size: 0.75rem;
	margin-right: 1rem;
}
.distribution .guess-count {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	line-height: 0.875rem;
	height: 14px;
	margin-bottom: 1px;
}
.distribution .index {
	flex-basis: 1rem;
	flex-shrink: 0;
	color: var(--color-gray300);
}

.distribution .guess-count.last-game .index {
	font-weight: bold;
	color: #fff;
}
.distribution .bar {
	height: 14px;
	width: 3%;
}
.distribution .count {
	font-weight: bold;
	padding: 0 0.25rem;
}

.prev-stats {
	padding: 0.5rem 0 1rem 0;
}
.my-games {
	display: grid;
	grid-template-columns: repeat(7, 1.5rem);
	grid-auto-rows: 1.5rem;
	gap: 0.5rem;
	padding-top: 0;
	justify-content: center;
}
.guess-circle {
	border-radius: 50%;
	box-sizing: border-box;
	border: 2px solid var(--color-gray900);
	box-shadow: 0 0 1px 2px var(--color-gray700);
	line-height: 20px;
	text-align: center;
	font-weight: bold;
	font-size: 0.875rem;
	padding: 0;
	margin: 0;
	color: var(--color-gray900);
}
.guess-circle.success {
	box-shadow: 0 0 1px 2px var(--color-success);
}
.guess-circle.fail {
	font-size: 0.875rem;
	color: var(--color-wrong);
	background: var(--color-gray800);
	box-shadow: 0 0 1px 2px var(--color-wrong);
}
.open-my-games {
	cursor: pointer;
	background: transparent;
	border: 0;
	margin-top: 1rem;
	color: var(--color-action-bright);
}
.relative-performance {
	font-size: 0.875rem;
	padding: 1rem;
	color: var(--color-gray100);
}
.hcp {
	display: inline-block;
	background: var(--color-gray800);
	padding: 0.25rem 0.75rem;
	margin: 1rem 0 0 0;
	border-radius: 1rem;
	color: var(--color-gray200);
	font-size: 0.875rem;
	cursor: pointer;
}
.hcp svg {
	width: 1rem;
	height: 1rem;
	vertical-align: bottom;
	color: var(--color-gray400);
}
.hcp-howto {
	font-size: 0.875rem;
	text-align: left;
	padding: 1rem;
	border-radius: 1rem;
	border: 1px solid var(--color-gray800);
	margin-top: 1rem;
}
.hcp-howto h4 {
	margin: 0;
}
.unknown-word-hint {
	background: var(--color-gray800);
	font-size: 0.875rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
	margin-top: 0.75rem;
	position: relative;
	color: var(--color-gray200);
}
.unknown-word-hint:after {
	bottom: 100%;
	left: 4.75rem;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: var(--color-gray800);
	border-width: 0.5rem;
}
.poll-options {
	display: block;
}
.poll-options button {
	background: var(--color-action);
	border: 0;
	border-radius: 0.5rem;
	color: #fff;
	font-weight: bold;
	display: block;
	padding: 1rem;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	margin-bottom: 0.5rem;
	font-size: 1rem;
}
.announcement {
	text-align: left;
	padding: 1rem;
	border-radius: 1rem;
	border: 1px solid var(--color-gray800);
	margin-top: 1rem;
}
.announcement button {
	cursor: pointer;
	background: transparent;
	border: 0;
	display: block;
	margin: auto;
	color: var(--color-action-bright);
}
.announcement h3 {
	margin: 0;
	padding: 0;
}
.announcement table {
	font-size: 0.875rem;
	width: 100%;
}
.billpayer {
	display: inline-block;
	width: 320px;
	height: 250px;
	max-height: 250px;
	margin: 0.5rem -1rem 0 -1rem;
}

@media (max-width: 320px) {
	.billpayer {
		width: 300px;
		height: 100px;
		max-height: 100px;
	}
}

.billpayerdisclaimer {
	font-size: 0.75rem;
	margin: 0 1rem 1rem 1rem;
	padding: 0.5rem 0;
	border-bottom: 1px solid var(--color-gray800);
	color: var(--color-gray400);
}
.billpayerdisclaimer svg {
	width: 1rem;
}
.account-cta {
	font-size: 0.875rem;
	text-align: left;
	background-color: var(--color-gray800);
	padding: 1rem;
	border-radius: 1rem;
	margin: 1rem 0 1rem 0;
	position: relative;
}
.account-cta:after {
	bottom: 100%;
	left: 3.5rem;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: var(--color-gray800);
	border-width: 1rem;
	margin-left: -1rem;
}
.account-cta h3 {
	margin: 0;
	padding: 0;
	font-size: 1rem;
}
.account-cta p {
	margin: 0.5rem 0;
}
.account-cta button.cta {
	background: var(--color-action);
	border: 0;
	border-radius: 0.5rem;
	color: #fff;
	font-weight: bold;
	display: block;
	padding: 1rem;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	font-size: 1rem;
	margin-top: 1rem;
}

.app-push {
	padding: 1rem;
	border: 1px solid var(--color-gray800);
	border-radius: 16px;
	margin: 0 0 1rem 0;
	font-size: 0.75rem;
	color: var(--color-gray300);
	line-height: 1.5;
	text-align: left;
}
.app-push h3 {
	font-size: 16px;
	margin: 0;
	padding: 0;
	color: var(--color-gray100);
}
.app-push ul {
	padding: 0.5rem 0;
	margin: 0 0 0 1rem;
}
.app-push a,
a.continue-to-app {
	color: var(--color-action-bright);
	text-decoration: none;
}
.hcp-howto table {
	border-spacing: 0;
}
.hcp-howto table th {
	position: sticky;
	top: 0;
	background: var(--color-gray900);
	padding: 0.5rem;
	border-bottom: 1px solid var(--color-gray400);
}
.hcp-howto td {
	padding: 0 0.5rem;
	line-height: 1.5;
	white-space: nowrap;
}
.hcp-howto tr:nth-child(odd) {
	background: rgba(0, 0, 0, 0.15);
}
.hcp-howto td:first-of-type,
.hcp-howto th:first-of-type {
	padding-left: 1rem;
}
.hcp-howto td:last-of-type,
.hcp-howto th:last-of-type {
	padding-right: 1rem;
}

.small-guess-circle {
	border-radius: 0.25rem;
	display: inline-block;
	margin-left: 0.25rem;
	box-sizing: border-box;
	line-height: 0;
	padding: 0;
	width: 0.5rem;
	height: 0.5rem;
}

.privacy {
	display: block;
	text-align: right;
	font-size: 0.75rem;
	line-height: 1.5;
	color: var(--color-gray400);
	text-decoration: none;
	overflow: hidden;
	text-overflow: ellipsis;
}
