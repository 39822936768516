:root {
	--color-gray900: #0f172a;
	--color-gray800: #1e293b;
	--color-gray700: #334155;
	--color-gray600: #475569;
	--color-gray500: #64748b;
	--color-gray400: #94a3b8;
	--color-gray300: #cbd5e1;
	--color-gray200: #e2e8f0;
	--color-gray100: #f1f5f9;
	--color-hint: #6d28d9;
	--color-success: #16a34a;
	--color-action: #0369a1;
	--color-action-bright: #0ea5e9;
	--color-action-dark: #0c4a6e;
	--color-wrong: #be123c;
	--color-stats1: #6ee7b7;
	--color-stats2: #34d399;
	--color-stats3: #10b981;
	--color-stats4: #059669;
	--color-stats5: #047857;
	--color-stats6: #065f46;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--color-gray800);
	margin: 0;
	padding: 0;
}

html {
	touch-action: pan-y;
}
body,
html,
#root {
	width: 100%;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
